#site-label-textfield {
  min-height: 100vh;
  background: #ffffff;
  border-radius: 10px;
}
.border-radius-10 {
  border-radius: 10px;
}
#site-label-text-field {
  .MuiOutlinedInput-adornedStart {
    padding-left: 0px !important;
  }
  .MuiInputAdornment-positionEnd {
    max-width: 80%;
  }
}
.textfield-border-gray {
  border: 1px solid #70707087 !important;
}
