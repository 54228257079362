#quick-deals {
    .h-90 {
        height: 90% !important;
    }
    .h-60 {
        height: 60% !important;
    }
    .h-40 {
        height: 40% !important;
    }
    .h-10 {
        height: 10% !important;
    }
    .image-curve {
        clip-path: ellipse(150% 100% at 50% 0);
        -webkit-clip-path: ellipse(100% 100% at 50% 0);
    }
    .tag-style {
        z-index: 1;
        height: 40px;
    }
    .content-style {
        font-family: inherit !important;
    }
}
