.search{
    .icon {
        cursor: pointer;
    }
    .transition{
       width: 100%;
       border-radius: 5px;
       transition: 0.5s;
    }
    .noTransition{
        height:0px;
        width: 0px;
        // transition: 0.5s;
        // TODO TEST SEARCH PANEL
    }
    
}
