#best-deals {
    .h-90{
        height: 90%;
    }
    .h-60 {
        height: 60% !important;
    }
    .h-15 {
        height: 15% !important;
    }
    .h-10 {
        height: 10% !important;
    }
    .tag-style {
        z-index: 1;
        height: 40px;
    }
    .content-style {
        font-family: inherit !important;
    }
}
