#best-deals-carousel {
    .carousel-best-deals-items {
        height: 400px !important;
    }
    .slick-prev:before {
        content: "" !important;
    }
    .slick-next:before {
        content: "" !important;
    }
    .slick-next {
        right: 0px !important;
    }
    .slick-prev {
        position: absolute !important;
        z-index: 1 !important;
    }
    #left_arrow_carousel-best-deals {
        font-size: 14px !important;
    }
    #right_arrow_carousel-best-deals {
        font-size: 14px !important;
    }
    .carousel-best-deals {
        outline: none !important;
        box-shadow: 0px 0px 3px #525252 !important;
    }
    .carousel-best-deals:hover {
        background-color: rgb(252, 252, 252) !important;
    }
}