#category-box-panel-carousel {
    .slick-prev:before {
        content: "" !important;
    }
    .slick-next:before {
        content: "" !important;
    }
    .slick-prev {
        position: absolute !important;
        z-index: 1 !important;
    }
    .slick-next {
        right: 0px !important;
    }
    #left_arrow_carousel2 {
        font-size: 14px !important;
    }
    #right_arrow_carousel2 {
        font-size: 14px !important;
    }
    .carousel2 {
        outline: none !important;
        box-shadow: 0px 0px 3px #525252 !important;
    }
    .carousel2:hover {
        background-color: rgb(252, 252, 252) !important;
    }
}
