#checkout{
   .border-outline{
       border: 1px solid lightgray;
       border-radius: 5px;
   }
   .radio{
       margin: 0;
       padding: 0;
   }
   .css-1ec97aa-MuiInputBase-root-MuiOutlinedInput-root {
       padding-left: 0;
   }
   .unique .css-lvxaa1-MuiTypography-root {
    color: lightgray;
}
}