#simple-navigation-box-carousel {
    .boxwidth {
        width: 110px !important;
        height: 110px !important;
    }
    .arrow-style {
        padding: 5px;
        font-size: 14px;
    }
    .slick-prev:before {
        content: "" !important;
    }
    .slick-next:before {
        content: "" !important;
    }
    .slick-next {
        right: 0px !important;
    }
    .slick-prev {
        position: absolute !important;
        z-index: 1 !important;
    }
}
