 #shopping-cart-item{
    .h-90 {
        height: 90%;
    }
    .h-10 {
        height: 10%;
    }
    .cart-img {
        border-radius: 27px !important;
    }
    .text-style{
        min-width: 100px;
    }
}
